import { Classification } from '@zupr/types/fo'

import { ReactComponent as Chevron } from '../../../../../svg/chevron.svg'
import LinkChangeQuery from '../../../../shared/router/link-change-query'
import CollapsableFilter from '../../../components/search/filter/collapse'
import { FilterLink } from '../../../components/search/filter/link'
import RemoveFilter from '../../../components/search/filter/remove'
interface RemoveClassificationsProps {
    classifications?: Classification[]
    baseUrl?: string
}

export const RemoveClassifications = ({
    baseUrl,
    classifications,
}: RemoveClassificationsProps) => {
    if (!classifications?.length) return null
    return (
        <>
            {classifications.map((classification) => (
                <RemoveFilter
                    href={baseUrl}
                    filter="classification"
                    removeFromValue={classification.slug}
                    value={classification.path.split('.').slice(-2).join(',')}
                >
                    {classification.name}
                </RemoveFilter>
            ))}
        </>
    )
}

interface Props {
    classificationBreadcrumbs: Classification[]
    classifications: Classification[]
    activeClassification: Classification
}

const LocationClassifications = ({
    classifications,
    activeClassification,
    classificationBreadcrumbs,
}: Props) => {
    // get the parent classification
    const parent =
        classificationBreadcrumbs.length > 1 &&
        classificationBreadcrumbs[classificationBreadcrumbs.length - 2]

    return (
        <CollapsableFilter header="Categories">
            {activeClassification && (
                <LinkChangeQuery
                    query={{
                        classification: parent?.slug || null,
                    }}
                    className="filter-back"
                >
                    <Chevron />
                    {activeClassification.name}
                </LinkChangeQuery>
            )}

            {classifications.map(({ slug, name, locationCount }) => (
                <FilterLink
                    key={slug}
                    query={{ classification: slug }}
                    count={locationCount}
                >
                    {name}
                </FilterLink>
            ))}
        </CollapsableFilter>
    )
}

export default LocationClassifications
