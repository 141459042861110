import React, { useContext } from 'react'
import { useRouter } from 'next/router'
import { Variables } from '@zupr/types/fo'
import { filterKeys } from '@zupr/next/helpers/locations'

import Trans from '../../../../shared/components/trans'
import RemoveFilter from '../../../components/search/filter/remove'

import RouteContext from '../../../../context/route'
import SwitchField from '../../../../shared/form/fields/switch'
import { useShopperLocation } from '../../../../context/shopper'

interface Props {
    variables: Variables
}

export const RemoveShopperLocation = () => {
    const { distance, yourLocation } = useShopperLocation()
    return (
        <RemoveFilter filter="box" value="false">
            {yourLocation}
            {` (+${distance}km)`}
        </RemoveFilter>
    )
}

const ShopperLocation = ({ variables }: Props) => {
    const { push } = useRouter()
    const { changeQuery } = useContext(RouteContext)
    const { box } = useShopperLocation()

    const field = {
        name: 'box',
        value: !!variables[filterKeys.box],
        setValue: () => {
            push(
                changeQuery({
                    box: variables[filterKeys.box] ? 'false' : box,
                })
            )
        },
    }

    if (!box) return null

    return (
        <div className="sidebar-filter">
            <div className="filter-header">
                <label htmlFor="box">
                    <Trans label="Your location" dutch />
                </label>
                <SwitchField field={field} />
            </div>
        </div>
    )
}

export default ShopperLocation
