import { Aggregations } from '@zupr/types/fo'
import React from 'react'

import CollapsableFilter from '../../../components/search/filter/collapse'
import { FilterLink } from '../../../components/search/filter/link'

interface CityFilterProps {
    aggregations: Aggregations
    baseUrl?: string
    useSlugs?: boolean
    query?: any
}

const CityFilter = ({
    query,
    aggregations,
    baseUrl,
    useSlugs,
}: CityFilterProps) => {
    const cities = aggregations.data?.city?.buckets || []
    if (cities.length < 3) return null
    return (
        <CollapsableFilter header="City">
            {cities.map(({ doc_count, key }) =>
                useSlugs ? (
                    <FilterLink
                        key={key}
                        query={query}
                        href={`${baseUrl}/${encodeURIComponent(key)}`}
                        count={doc_count}
                    >
                        {key}
                    </FilterLink>
                ) : (
                    <FilterLink
                        key={key}
                        query={{ ...query, city: key }}
                        count={doc_count}
                    >
                        {key}
                    </FilterLink>
                )
            )}
        </CollapsableFilter>
    )
}

export default CityFilter
