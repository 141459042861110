import { t } from '@zupr/i18n'
import { LocationsAndAggregations } from '@zupr/types/next'
import { dayString } from '@zupr/utils/datetime'
import { useRouter } from 'next/router'
import { useContext } from 'react'

import { aggregationKeys, filterKeys } from '@zupr/next/helpers/locations'

import AreaContext from '../../../context/domain'
import RouteContext from '../../../context/route'
import { useIsMobile } from '../../../context/ux'

import CollapsableFilter from '../../components/search/filter/collapse'
import FilterContainer from '../../components/search/filter/container'
import RemoveFilter from '../../components/search/filter/remove'
import RemoveAllFilters from '../../components/search/filter/remove-all'

import { FilterSelect } from '../../components/search/filter/multiselect'
import FilterSearch, {
    RemoveSearchFilters,
} from '../../components/search/filter/search'

import Trans from '../../../shared/components/trans'
import SwitchField from '../../../shared/form/fields/switch'

import PaginationSort from '../../../shared/components/pagination/sort'

import ViewMap from './components/view-map'

import CityFilter from './filters/city'
import LocationClassifications, {
    RemoveClassifications,
} from './filters/classification'
import ShopperLocationFilter, {
    RemoveShopperLocation,
} from './filters/shopper-location'

import '../../../../scss/react/filter/filters.scss'

export interface LocationsFiltersProps extends LocationsAndAggregations {
    mapView?: boolean
}

const LocationsFilters = ({
    aggregations,
    locations,
    mapView,
    classificationBreadcrumbs,
    filter,
    classifications,
    activeClassification,
}: LocationsFiltersProps) => {
    const isMobile = useIsMobile()
    const { shoppingAreaSlug } = useContext(AreaContext)
    const { changeQuery } = useContext(RouteContext)
    const { query, push } = useRouter()

    const { variables } = filter

    const removeable = {
        ordering: query.ordering && t(query.ordering, 'filter'),
        type: query.type && t(query.type, 'types'),
        delivery: t('Lokale bezorging', 'dutch'),
        reservation: t('Reserveren', 'dutch'),
        collect: t('Afhalen', 'dutch'),
        open: t('Open today'),
        city: query.city,
    }

    return (
        <FilterContainer
            resultCount={locations.count}
            filterCount={filter.filterCount}
            renderFilters={
                <div className="filters">
                    {!isMobile && <ViewMap mapView={mapView} />}

                    {filter.filterCount > 0 && (
                        <div className="sidebar-filter">
                            <div className="filter-header">
                                <h4>{t('Gekozen filters', 'dutch')}</h4>
                                <RemoveAllFilters
                                    filterKeys={filterKeys}
                                    query={
                                        !!variables[filterKeys.box] && {
                                            box: 'false',
                                        }
                                    }
                                />
                            </div>

                            <RemoveClassifications
                                classifications={classificationBreadcrumbs}
                            />

                            {Object.keys(removeable)
                                .filter((key) => !!query[key])
                                .map((key) => (
                                    <RemoveFilter filter={key}>
                                        {removeable[key]}
                                    </RemoveFilter>
                                ))}

                            {query.brands && (
                                <RemoveSearchFilters
                                    url="fo/brand"
                                    filter="brands"
                                    selected={query.brands}
                                />
                            )}

                            {variables[filterKeys.box] && (
                                <RemoveShopperLocation />
                            )}
                        </div>
                    )}

                    {isMobile && (
                        <div className="sidebar-filter">
                            <PaginationSort
                                defaultOrdering="-created"
                                choices={['-created', 'title']}
                            />
                        </div>
                    )}

                    <div className="sidebar-filter">
                        <div className="filter-header">
                            <label htmlFor="opening-hours">
                                <Trans label="Open today" />
                            </label>
                            <SwitchField
                                field={{
                                    name: 'opening-hours',
                                    value: !!query.open,
                                    setValue: () => {
                                        push(
                                            changeQuery({
                                                open: query.open
                                                    ? null
                                                    : dayString(),
                                            })
                                        )
                                    },
                                }}
                            />
                        </div>
                    </div>

                    <ShopperLocationFilter variables={variables} />

                    <CollapsableFilter header="Afhalen of bezorgen" dutch>
                        <div className="multiselect-filter">
                            <FilterSelect
                                query={{
                                    delivery: query.delivery
                                        ? null
                                        : shoppingAreaSlug,
                                }}
                                name="delivery"
                                checked={!!query.delivery}
                            >
                                <Trans label="Laat bezorgen" dutch />
                            </FilterSelect>
                            <FilterSelect
                                query={{
                                    reservation: query.reservation
                                        ? null
                                        : true,
                                }}
                                name="reservation"
                                checked={!!query.reservation}
                            >
                                <Trans label="Reserveren" dutch />
                            </FilterSelect>
                            <FilterSelect
                                query={{
                                    collect: query.collect ? null : true,
                                }}
                                name="collect"
                                checked={!!query.collect}
                            >
                                <Trans label="Afhalen" dutch />
                            </FilterSelect>
                        </div>
                    </CollapsableFilter>

                    {isMobile && (
                        <LocationClassifications
                            classifications={classifications}
                            activeClassification={activeClassification}
                            classificationBreadcrumbs={
                                classificationBreadcrumbs
                            }
                        />
                    )}

                    <CityFilter aggregations={aggregations} />

                    <CollapsableFilter header="Brand">
                        <FilterSearch
                            filter="brands"
                            url="fo/brand"
                            selected={query.brands as string}
                            aggregationKey={aggregationKeys.brand}
                            buckets={
                                aggregations.data?.[aggregationKeys.brand]
                                    ?.buckets
                            }
                        />
                    </CollapsableFilter>
                </div>
            }
        />
    )
}

export default LocationsFilters
